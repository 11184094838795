<template>
  <div id="tax">
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Vehicle Tax</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="is-valigned" :class="`has-text-${colour}`" v-if="date">
            {{ formattedDate }}&nbsp;
          </span>
          <span class="tag is-valigned" :class="`is-${colour}`">
            {{ statusText }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckTax',
  props: {
    data: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    formattedDate() {
      return formatDate(this.date)
    },
    colour() {
      return this.status === 'Taxed' ? 'success' : 'warning'
    },
    status() {
      return this.data?.taxStatus
    },
    statusText() {
      const { status } = this
      if (!status || status === 'No results returned') return 'Not available'
      else return status
    },
    date() {
      return this.data?.taxDueDate
    }
  }
}
</script>
